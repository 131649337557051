<template>
  <v-form lazy-validation ref="form" v-model="valid">
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                <v-autocomplete
                  v-model="form.bankId"
                  outlined
                  dense
                  flat
                  item-text="name"
                  item-value="id"
                  background-color="white"
                  :items="listBank"
                  :rules="[v => !!v || 'Bank is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Suffix</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                <v-text-field
                  v-model="form.suffix"
                  outlined
                  dense
                  flat
                  background-color="white"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Start <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                <v-text-field
                  v-model="form.start"
                  outlined
                  dense
                  flat
                  background-color="white"
                  :rules="[v => !!v || 'Start is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Total <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                <v-text-field
                  v-model.number="form.total"
                  outlined
                  dense
                  flat
                  background-color="white"
                  type="number"
                  :rules="[v => !!v || 'Total is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-data-table :headers="headers" :items="items">
              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <div>Panduan Pengisian Form Check</div>
        <div>1. Suffix adalah bagian awal dari no check</div>
        <div>2. Start adalah angka yang akan mengalami sequence</div>
        <div>3. Total adalah jumlah no check yang di dapat</div>
        <div>4. Bank adalah Bank yang mengeluarkan no check</div>
        <div>======================================================</div>
        <div>Contoh : Total NO CHECK adalah 100 lembar dan berawal dari no XL56785</div>
        <div>Pada No check di atas :</div>
        <div>Suffix = XL</div>
        <div>Start = 56785</div>
        <div>Total = 100</div>
        <div>======================================================</div>
        <div>Contoh : Total NO CHECK adalah 40 lembar dan berawal dari no 0000657</div>
        <div>Pada No check di atas :</div>
        <div>Suffix = kosong</div>
        <div>Start = 0000657</div>
        <div>Total = 40</div>
        <div>======================================================</div>
        <div>Contoh : Total NO CHECK adalah 70 lembar dan berawal dari no XL000P00657</div>
        <div>Pada No check di atas :</div>
        <div>Suffix = XL000P</div>
        <div>Start = 00657</div>
        <div>Total = 70</div>
        <div>======================================================</div>
        <div>NOTE : MOHON UNTUK MENGIKUTI SESUAI CONTOH DIATAS SAAT PENGISIAN FORM</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="12">
        <v-btn class="mr-4" @click="generate" color="success">
          Generate No Check
        </v-btn>
        <v-btn class="mr-4" @click="submit" v-if="active" color="primary">
          submit
        </v-btn>
        <v-btn @click="clear">
          clear
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "create-check",
  data: () => ({
    defaultForm: {
      bankId: null,
      suffix: "",
      start: "",
      total: 0,
    },
    form: {},
    valid: true,
    items: [],
    active: false,
    headers: [
      { text: "No", value: "no" },
      { text: "No Check", value: "checkNumber" },
    ],
  }),

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.commit("SET_LOADER", true);
        this.$store
          .dispatch("check/create", this.items)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    generate() {
      this.items = [];
      for (let index = 0; index < this.form.total; index++) {
        const ctx = "" + (parseInt(this.form.start) + index);
        const end = this.form.start.substr(0, this.form.start.length - ctx.length) + ctx;
        const number = this.form.suffix + end;
        this.items.push({
          bankId: this.form.bankId,
          checkNumber: number,
        });
      }
      this.active = true;
    },
    getBank() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("bank/dropdown")
        .then(() => {
          this.$store.commit("SET_LOADER", false);
          this.form = Object.assign({}, this.defaultForm);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    clear() {
      this.$refs.form.reset();
      this.form = Object.assign({}, this.defaultForm);
      this.active = false;
      this.items = [];
    },
  },
  computed: {
    ...mapState("bank", ["listBank"]),
  },
  mounted() {
    this.getBank();
  },
};
</script>

<style></style>
